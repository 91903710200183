import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
// import HomeV1 from './components/home-v1';
import HomeV2 from './components/home-v2';
// import HomeV3 from './components/home-v3';
// import PropertyDetails from './components/property-details';
import ProptertyGrid from './components/property-grid';
import Propterty from './components/property';
import About from './components/about';
import Team from './components/team';
import SignIn from './components/sign-in';
import SignUp from './components/sign-up';
import ResetPassword from './components/reset-password';
import ForgotPassword from './components/forgot-password';
import AddProperty from './components/add-property';
import Contact from './components/contact';
import Blog from './components/blog';
import BlogDetails from './components/blog-details';
import PrivateRoute from './components/global-components/privateRoute';
import ProjectDetails from './components/project-details';
import Ideation from './components/ideation';
import Funding from './components/funding';
import IdeationSummary from './components/ideation-summary';
import FundingSummary from './components/funding-summary';
import Transaction from './components/transaction';
import Log from './components/log';
import LogDetail from './components/log-detail';
import ProptertyFunding from './components/property-funding';
import ProjectFundingDetails from './components/project-funding-details';
import KycAdd from './components/kyc-add';


class Root extends Component {
    render() {
        return(
                <Router basename="/">
	                <div>
                    <ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
				/>
	                <Switch>
                        <Route exact path="/" component={HomeV2} />
                        {/* <Route  path="/home-v2" component={HomeV2} />
                        <Route  path="/home-v3" component={HomeV3} /> */}
                        <PrivateRoute exact path="/l/project/:id" component={ProjectDetails} />
                        <PrivateRoute exact path="/f/project/:id" component={ProjectFundingDetails} />
                        {/* <Route  path="/property-grid" component={ProptertyGrid} /> */}
                        <PrivateRoute exact  path="/l/project" component={Propterty} />
                        <PrivateRoute exact  path="/f/project" component={ProptertyFunding} />
                        <Route  path="/about" component={About} />
                        <Route  path="/team" component={Team} />
                        <Route  path="/sign-in" component={SignIn} />
                        <Route  path="/sign-up" component={SignUp} />
                        <Route  path="/forgot-password" component={ForgotPassword} />
                        <Route  path="/reset-password/:token" component={ResetPassword} />
                        {/* <Route  path="/add-property" component={AddProperty} /> */}
                        {/* <Route  path="/contact" component={Contact} /> */}
                        {/* <Route  path="/blog" component={Blog} /> */}
	                    <Route  path="/blog-details" component={BlogDetails} />
                        <PrivateRoute  path="/ideation" component={Ideation} />
                        <PrivateRoute path="/funding" component={Funding} />
                        <PrivateRoute  path="/interested/:id" component={IdeationSummary} />
                        <PrivateRoute exact path="/invested/:id" component={FundingSummary} />
                        <PrivateRoute exact path="/transaction" component={Transaction} />
                        <PrivateRoute  exact path="/log/:id" component={LogDetail} />
                        <PrivateRoute exact path="/log" component={Log} />
                        <PrivateRoute exact path="/kyc/add" component={KycAdd} />
	                </Switch>
	                </div>
                </Router>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('Launch A Venture'));
