import 'react-toastify/dist/ReactToastify.min.css';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import parse from 'html-react-parser';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { serviceUrl, tokenName, iName } from '../../constant';

const validatePassword = (value) => {
	let error;
	if (!value) {
	  error = 'Please enter your password';
	} else if (value.length < 4) {
	  error = 'Value must be longer than 3 characters';
	}
	return error;
  };
  
  const validateEmail = (value) => {
	let error;
	if (!value) {
	  error = 'Please enter your email address';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
	  error = 'Invalid email address';
	}
	return error;
  };
  

class SignIn extends Component {
	state={
		username:"",
		password:"",
		type: false, 
		// this.onSubmit()=this.onSubmit.bind(this),
	}
	
	onSubmit=async(values)=>{
		// e.preventDefault();
		if (values.username !== '' && values.password !== '') {
			// console.log("value",values);
			try {
				let login = await axios.post(`${serviceUrl}investor/login`, values);
				// const project = project2.data.data.items[0].projects;
				//   this.setState ({project});
				// console.log("login",login);
				// console.log("suces1s",login.headers['x-id-token']);
				if(login.status===200){
					// NotificationManager.success('Login sucessful', `Welcome ${login.data.data.items[0].name}`);
					localStorage.setItem(tokenName,login.headers['x-id-token']);
					localStorage.setItem(iName, login.data.data.items[0].name);
					toast.success(`Login Successful`, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
						});
					this.props.history.push('/l/project');
					// console.log("sucess");
				}
				//   console.log(login);
			
				// Work with the response...
			} catch (err) {
				if (err.response) {
					// The client was given an error response (5xx, 4xx)
					//   console.log("err response",err.response.status);
					//   NotificationManager.error( err.response.data.message, 'Error message', 5000);
					if(err.response.data.errors?.length > 0 ){
					toast.error(err.response.data.errors[0].message, {
						position: "top-center",
						autoClose: 8000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
						});
					}
					if(err.response.status === 403){
					toast.error(err.response.data.data.kind, {
						position: "top-center",
						autoClose: 8000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
						});	
				}
					if(err.response.data.message){
						toast.error(err.response.data.message, {
							position: "top-center",
							autoClose: 8000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "light",
							});
					}
					//    console.log(err.response.status);
					//   console.log(err.response.headers);
				} else if (err.request) {
					// The client never received a response, and the request was never left
					console.log("err, req",err.request);
				} else {
					// Anything else
					console.log('Error', err.message);
				}
			}
		}
	}

	componentDidMount() {

     const $ = window.$;

     $( 'body' ).addClass( 'bg-gray' );

 	}

    render() {

        // let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="signin-page-area pd-top-100 ">
			  <div className="container">
			    <div className="row justify-content-center">
				{/* <ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
				/> */}
			      <div className="col-xl-7 col-lg-8">
				  <Formik   initialValues={{
					password: '',
					username: '',
					}}
					onSubmit={(values)=>this.onSubmit(values)}>
              {({ errors, touched }) => (
                <Form className="signin-inner">
			        {/* <form > */}
			          <div className="row">
			            <div className="col-12">
			              <label className="single-input-inner style-bg-border"> Email
						  <Field
                      name="username"
                      validate={validateEmail}
					//   onChange={(e)=>this.setState({email:e.target.value})}
                    />
                    {errors.username && touched.username && (
                      <div className='warn'>
                        {errors.username}
                      </div>
                    )}
			              </label>
			            </div>
			            <div className="col-12">
			              <label className="single-input-inner style-bg-border"> Password
			                {/* <input type={this.state.type ? "text": "password"} name="password" placeholder="Password" onChange={(e)=>this.setState({password:e.target.value})}/>
							 */}
							 <Field
                      type={this.state.type ? "text": "password"}
                      name="password"
                      validate={validatePassword}
                    />
                    {errors.password && touched.password && (
                      <div className='warn'>
                        {errors.password}
                      </div>
                    )}
							<button onClick={(e)=>(e.preventDefault(),this.setState(prevState=>({type: !prevState.type})))}>show password</button>
			              </label>
						  
			            </div>
			            <div className="col-12 mb-4">
			              <button type="submit" className="btn btn-base w-100" >Sign In</button>
			            </div>
						
			          </div>
					  <div className='row'>
						<div className="col-6">
			              {/* <a href="#">Forgottem Your Password</a> */}
			               <Link to="/forgot-password"><strong>Forgot Password</strong></Link>
			            </div>
			            <div className="col-6">
			              {/* <a href="#">Forgottem Your Password</a> */}
			              Not A member? <Link to="/sign-up"><strong>Sign Up</strong></Link>
			            </div>
						
						</div>
			        {/* </form> */}
					</Form>
              )}
            </Formik>
			
			      </div>
			    </div>
			  </div>
			  
			</div>
        }
}

export default withRouter(SignIn)