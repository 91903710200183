import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer_v1 extends Component {

	componentDidMount() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		const minscript = document.createElement("script");
		minscript.async = true;
		minscript.src = publicUrl + "assets/js/main.js";

		document.body.appendChild(minscript);
	}

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return (
			<footer id="contact" className="footer-area">
				<div className="footer-top">
					<div className="container">
						<div className="row">
							<div className="col-md-4">
								<a href="index.html"><img src={publicUrl + "assets/img/logo.png"} alt="img" /></a>
							</div>
							<div className="col-md-8 text-md-right mt-3 mt-md-0">
								<ul className="social-area">
									<li><a href="https://www.facebook.com/launchaventure" target='_blank'><i className="fab fa-facebook-f" aria-hidden="true" /></a></li>
									<li><a href="https://twitter.com/launchaventure" target='_blank'><i className="fab fa-twitter" aria-hidden="true" /></a></li>
									<li><a href="https://www.instagram.com/launchaventure/" target='_blank'><i className="fab fa-instagram" aria-hidden="true" /></a></li>
									{/* <li><a href="https://www.skype.com/Softmarvel/"><i className="fab fa-skype" aria-hidden="true" /></a></li> */}
									{/* <li><a href="https://www.pinterest.com/Softmarvel/"><i className="fab fa-pinterest-p" aria-hidden="true" /></a></li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-middle bg-black">
					<div className="container">
						<div className="row">
							<div className="col-lg-4 col-md-6">
								<div className="widget widget_about">
									<h4 className="widget-title">Launch A Venture</h4>
									<div className="details">
										{/* <p></p> */}
										<p><i className="fas fa-map-marker-alt" /> Launch A Venture <br/>LAV Business Solutions Pvt Ltd <br/> Datoda, Near Ganesh Mandir, Khandwa Road, Indore, Madhya Pradesh - 452020</p>
										<p><i className="fas fa-phone-volume" /> +(91) 7722881535</p>
										<p><i className="fas fa-envelope" /> contact@LaunchAVenture.in</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6">
								<div className="widget widget_newsfeed">
									<h4 className="widget-title">Services</h4>
									<ul className="go-top">
										<li>Startup Funding</li>
										<li>Startup Services</li>
										<li>Legal Services</li>
										<li>Investment Scope</li>
										<li>Software Solutions</li>
										<li>PR & Branding</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-4 col-md-9">
								<div className="widget widget_subscribe mb-4">
									<h4 className="widget-title">DISCLAIMER</h4>
									<div className="details">
										<p>Launch A Venture (LAV Business Solutions Pvt Ltd) connects startups and investors to enable the startup raise the required investments. Launch A Venture is not a stock exchange recognized by the Securities Exchange Board of India (SEBI) under the Securities Contract (Regulation) Act, 1956. Launch A Venture does not allow any secondary market trading of securities on the Platform and is only a facilitator for investments and startup services.</p>
										{/* <div className="footer-subscribe-inner">
											<input type="text" placeholder="Your Mail" />
											<a className="btn btn-base" href="#">Subscribe</a>
										</div> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-bottom bg-black">
					<div className="container">
						<div className="row">
							<div className="col-md-7 align-self-center">
								<p> Developed By Softmarvel.</p>
							</div>
							{/* <div className="col-md-5 text-md-right go-top">
								<ul>
									<li>
										<Link to="/">Home</Link>
									</li>
									<li>
										<Link to="/about">About</Link>
									</li>
									<li>
										<Link to="/blog">Blog</Link>
									</li>
									<li>
										<Link to="/contact">Contact</Link>
									</li>
								</ul>
							</div> */}
						</div>
					</div>
				</div>
			</footer>

		)
	}
}


export default Footer_v1